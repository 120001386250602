import {ISubscriptionPlan} from '../components/ProductOptions/SubscriptionPlans/SubscriptionPlan/SubscriptionPlan';
import * as _ from 'lodash';
import {IProductDTO, UserInput} from '../types/app-types';
import {GetProductBySlugQuery} from '../graphql/queries-schema';

export class SubscriptionService {
  constructor(
    private readonly subscriptionPlans: GetProductBySlugQuery['catalog']['product']['subscriptionPlans'],
    private readonly oneTimePurchaseInfo: {label: string; place: number; price: string}
  ) {}

  private mapSubscriptionDtoToSubscriptionPlan(item: IProductDTO['subscriptionPlans']['list'][0]): ISubscriptionPlan {
    return {
      ...item,
      isOneTimePurchase: false,
    };
  }

  /* istanbul ignore next */
  private getPlansListWithOneTimePurchaseAtIndex(list, index: number = 0) {
    return [
      ...list.slice(0, index).map(this.mapSubscriptionDtoToSubscriptionPlan),
      {
        id: undefined,
        name: this.oneTimePurchaseInfo.label,
        formattedPrice: this.oneTimePurchaseInfo.price,
        isOneTimePurchase: true,
      },
      ...list.slice(index).map(this.mapSubscriptionDtoToSubscriptionPlan),
    ];
  }

  public readonly getSubscriptionPlans = (): ISubscriptionPlan[] => {
    if (!this.subscriptionPlans || !this.subscriptionPlans.list || this.subscriptionPlans.list.length === 0) {
      return [];
    }

    if (!_.isNil(this.subscriptionPlans.oneTimePurchase)) {
      const oneTimePurchaseIndex = this.oneTimePurchaseInfo.place === 0 ? 0 : this.subscriptionPlans.list.length;
      return this.getPlansListWithOneTimePurchaseAtIndex(this.subscriptionPlans.list, oneTimePurchaseIndex);
    } else {
      return this.subscriptionPlans.list.map(this.mapSubscriptionDtoToSubscriptionPlan);
    }
  };

  public get shouldShowSubscriptionPlans(): boolean {
    return this.getSubscriptionPlans().length > 0;
  }

  public shouldShowSubscribeButton(userInputs: UserInput): boolean {
    return this.getSubscriptionPlans().length > 0 && !this.isOneTimePurchaseSelected(userInputs);
  }

  public isOneTimePurchaseSelected(userInputs: UserInput): boolean {
    return userInputs.subscriptionPlan.length > 0 && _.get(userInputs.subscriptionPlan, ['0']).isOneTimePurchase;
  }

  public setOneTimePurchasePlace(place: number): void {
    this.oneTimePurchaseInfo.place = place;
  }
}
