export const query = `query getCartService($cartId: String!) {
  cartService {
    cart(cartId: $cartId) {
      convertedTotals(withTax: true, withShipping: true) {
        subTotal
        total
        shipping
        discount
        itemsTotal
        tax
        formattedItemsTotal
        formattedSubTotal
        formattedShipping
        formattedDiscount
        formattedTax
        formattedTotal
      }
      shippingRuleInfo {
        status
        canShipToDestination
      }
      destinationCompleteness
    }
  }
}`;
